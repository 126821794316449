import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  Button,
  Form,
  Input,
  Label,
  FormGroup,
} from 'reactstrap';
import { submitComment } from '_actions/organisation.actions';
import ModalTrigger from './modalTrigger';
import FormInput from './formInput';

function InternalCommentModal({ id }) {
  const [comment, setComment] = useState('');
  const [visible, setVisible] = useState(true);
  const dispatch = useDispatch();
  const clear = () => {
    setComment('');
    setVisible(true);
  };
  const submit = () => {
    dispatch(submitComment({
      company_id: id,
      comment,
      visible,
    }));
    clear();
  };
  return (
    <ModalTrigger title="Add Note" trigger={<Button block>Add Note</Button>}>
      <Form>
        <FormInput
          label={false}
          inputProps={{
            onChange: (event) => setComment(event.target.value),
            name: 'comment',
            type: 'textarea',
            value: comment,
            placeholder: 'Enter a comment',
            rows: 5,
          }}
        />
        <FormGroup>
          <Input
            id="visible_checkbox"
            name="visible"
            type="checkbox"
            onChange={(event) => setVisible(event.target.checked)}
            checked={visible}
          />
          <Label for="visible_checkbox">Set visible</Label>
        </FormGroup>
        <Button color="success" disabled={isEmpty(comment)} onClick={submit}>Submit</Button>
      </Form>
    </ModalTrigger>
  );
}

InternalCommentModal.propTypes = {
  id: PropTypes.string.isRequired,
};

export default InternalCommentModal;
