import React from 'react';
import { useSelector } from 'react-redux';
import {
  UncontrolledCollapse, Card, CardHeader, CardBody, Table,
} from 'reactstrap';
import { map, round, isEmpty } from 'lodash';
import Currency from 'utilities/currency';
import { NoResultsFoundAlert } from 'utilities/alerts';
import BooleanIcon from './booleanIcon';

function Panel({ Customer }) {
  return (
    <Card key={Customer.id} className="card__details m-0 p-0">
      <CardHeader className="p-2" id={'panel-' + Customer.id}>{Customer.name}</CardHeader>
      <UncontrolledCollapse toggler={'panel-' + Customer.id}>
        <CardBody className="p-0 pt-2">
          <Table className="m-0" striped borderless responsive>
            <tbody>
              <tr>
                <th>IFS Index</th>
                <td>{Customer.id}</td>
              </tr>
              <tr>
                <th>Active</th>
                <td><BooleanIcon>{Number(Customer.is_active)}</BooleanIcon></td>
              </tr>
              <tr>
                <th>Credit Limit</th>
                <td><Currency value={Customer.credit_limit} /></td>
              </tr>
              <tr>
                <th>Balance</th>
                <td><Currency value={Customer.balance} /></td>
              </tr>
              <tr>
                <th>Adjusted Balance</th>
                <td><Currency value={Customer.adjusted_balance} /></td>
              </tr>
              <tr>
                <th>Remaining Credit</th>
                <td>
                  <Currency
                    value={round(Customer.credit_limit - Customer.adjusted_balance, 2)}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </UncontrolledCollapse>
    </Card>
  );
}

function CustomerList() {
  const { read: { Customer = [] } } = useSelector((state) => state.organisation);
  if (isEmpty(Customer)) {
    return <NoResultsFoundAlert />;
  }
  return map(Customer, (record, index) => <Panel key={index} Customer={record} />);
}

export default CustomerList;

