import organisationConstants from '_constants/organisation.constants';

export default function organisation(state = {}, action) {
  switch (action.type) {
    case organisationConstants.LIST_SUCCESS:
    case organisationConstants.LIST_FILTER_SUCCESS:
      return {
        ...state,
        list: action.organisations,
        filtered: action.filtered,
      };
    case organisationConstants.READ_SUCCESS:
      return {
        ...state,
        read: action.organisation,
      };
    case organisationConstants.READ_INDIVIDUALS_SUCCESS:
      return {
        ...state,
        readIndividuals: action.individuals,
      };
    case organisationConstants.ADD:
      return {};
    case organisationConstants.ADD_COMMENT:
      return {};
    default:
      return {
        list: [],
        filtered: [],
        read: {},
        readIndividuals: [],
        ...state,
      };
  }
}
