import React from 'react';
import { Button } from 'reactstrap';
import ModalTrigger from './modalTrigger';
import FilterOrganisations from './filterOrganisations';

function SearchCompanyModal({ title = 'Select Organisation', buttonProps = { color: 'primary' } }) {
  return (
    <ModalTrigger title={title} trigger={<Button block {...buttonProps}>{title}</Button>}>
      <FilterOrganisations callback={({ id }) => <Button href={'/order/create/' + id} block>Select Organisation</Button>} />
    </ModalTrigger>
  );
}

export default SearchCompanyModal;
