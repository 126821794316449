import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Button,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import FilterOrganisations from 'components/filterOrganisations';
import CreateOrderButton from 'components/createOrderButton';
import breadcrumbActions from '_actions/breadcrumb.actions';

function ListOrganisations() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(breadcrumbActions.add('Organisations', '/organisations'));
  }, [dispatch]);
  return (
    <FilterOrganisations
      callback={({ id }) => (
        <div>
          <Row>
            <Col>
              <Button href={'/organisation/' + id} block>View Details</Button>
            </Col>
            <Col>
              <CreateOrderButton organisationId={id} />
            </Col>
          </Row>
        </div>
      )}
    />
  );
}

export default ListOrganisations;
