import React from 'react';
import { Badge } from 'reactstrap';

function Presence({ children }) {
  if (children) {
    return children;
  }
  return <h6 className="p-0 m-0"><Badge color="warning" className="m-0">Unknown</Badge></h6>;
}

export default Presence;
