import { remove } from '_services/request.service';
import rehydrate from './rehydrate';
import blockActions from '_actions/block.actions';
import alertActions from '_actions/alert.actions';

function action({ id, shop_order_id }, relationType) {
  return (dispatch) => {
    dispatch(blockActions.block());
    remove(`/api_auth/orders/aamos/order_${relationType}_delete.json`, id).then(
      (result) => {
        if (result === false) {
          dispatch(blockActions.clear());
          dispatch(alertActions.error('Error deleting ' + relationType));
          return;
        }
        dispatch(rehydrate(shop_order_id, 'Successfully deleted ' + relationType));
      },
      (error) => {
        dispatch(blockActions.clear());
        dispatch(alertActions.error(error.toString()));
      },
    );
  };
}

export default action;
