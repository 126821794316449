import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { deleteRelatedRecord } from '_actions/order.actions';

function DeletePayment({ id, orderId }) {
  const dispatch = useDispatch();
  return <Button color="danger" size="sm" onClick={() => dispatch(deleteRelatedRecord({ id, shop_order_id: orderId }, 'payment'))} block>Delete</Button>;
}

export default DeletePayment;
