import { post } from '_services/request.service';
import alertActions from '_actions/alert.actions';
import blockActions from '_actions/block.actions';

function action(payload) {
  return (dispatch) => {
    dispatch(blockActions.block());
    post('/api_auth/orders/aamos/complete_order.json', payload).then(
      (result) => {
        if (result === false) {
          dispatch(blockActions.clear());
          dispatch(alertActions.error('Failed to complete order, please try again'));
          return;
        }
        dispatch(alertActions.success('Successfully completed order'));
        window.setTimeout(() => {
          window.location = '/dashboard?orderComplete=true';
        }, 2000);
      },
      (error) => {
        dispatch(blockActions.clear());
        dispatch(alertActions.error(error.toString()));
      },
    );
  };
}

export default action;
