import React, { useEffect } from 'react';
import {
  Button, Row, Col, Table,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { map, isEmpty } from 'lodash';
import OrderProductAdd from 'components/product/add';
import OrderProductEdit from 'components/product/edit';
import Currency from 'utilities/currency';
import AddDelivery from 'components/delivery/add';
import EditDelivery from 'components/delivery/edit';
import AddPayment from 'components/payment/add';
import DeletePayment from 'components/payment/delete';
import SetComments from 'components/order/setComments';
import { read, complete } from '_actions/order.actions';
import breadcrumbActions from '_actions/breadcrumb.actions';
import { NoResultsFoundAlert } from 'utilities/alerts';
import OrderWarning from 'components/orderWarning';
import InformationModal from 'components/product/informationModal';

const NoResultsRow = ({ colSpan, type }) => (
  <tbody>
    <tr>
      <td colSpan={colSpan}>
        <NoResultsFoundAlert text={`No ${type} added`} />
      </td>
    </tr>
  </tbody>
);

function Products() {
  const { OrderProduct } = useSelector((state) => state.order.read);
  let products = map(OrderProduct, (product, index) => (
    <tbody key={index}>
      <tr>
        <td className="text-left" colSpan={4}>
          <InformationModal product={product} />{' '}{product.title} {product.option_title && ' - ' + product.option_title}
        </td>
      </tr>
      <tr>
        <td>{product.quantity}</td>
        <td><Currency value={product.price} /></td>
        <td><Currency value={product.line_price} /></td>
        <td>
          <OrderProductEdit product={product}>
            <Button color="info" block size="sm">Edit</Button>
          </OrderProductEdit>
        </td>
      </tr>
    </tbody>
  ));
  if (isEmpty(products)) {
    products = <NoResultsRow colSpan={4} type="products" />;
  }
  return (
    <>
      <Row>
        <Col>
          <OrderProductAdd />
        </Col>
      </Row>
      <Table className="text-center mt-3" size="sm" bordered striped>
        <thead>
          <tr>
            <th>Quantity</th>
            <th>Unit Price</th>
            <th>Line Price</th>
            <th style={{ width: 70 }}>Edit</th>
          </tr>
        </thead>
        {products}
      </Table>
      <hr />
    </>
  );
}

function Deliveries() {
  const { OrderDelivery } = useSelector((state) => state.order.read);
  let deliveries = map(OrderDelivery, (delivery, index) => (
    <tbody key={index}>
      <tr>
        <td className="text-left" colSpan={4}>
          {delivery.title} {delivery.description && ' - ' + delivery.description}
        </td>
      </tr>
      <tr>
        <td><Currency value={delivery.price} /></td>
        <td>{Number(delivery.vat).toFixed(2) + '%'}</td>
        <td><Currency value={delivery.price * (1 + (delivery.vat / 100))} /></td>
        <td>
          <EditDelivery delivery={delivery} />
        </td>
      </tr>
    </tbody>
  ));
  if (isEmpty(deliveries)) {
    deliveries = <NoResultsRow colSpan={4} type="deliveries" />;
  }
  return (
    <>
      <Row>
        <Col>
          <AddDelivery />
        </Col>
      </Row>
      <Table className="text-center mt-3" size="sm" bordered striped>
        <thead>
          <tr>
            <th>Price</th>
            <th>VAT</th>
            <th>Gross</th>
            <th style={{ width: 70 }}>Edit</th>
          </tr>
        </thead>
        {deliveries}
      </Table>
      <hr />
    </>
  );
}

function Payments() {
  const { Payment, Order: { id: orderId } } = useSelector((state) => state.order.read);
  let payments = map(Payment, ({ id, amount, PaymentType }, index) => (
    <tbody key={index}>
      <tr>
        <td>{PaymentType.title}</td>
        <td><Currency value={amount} /></td>
        <td>
          <DeletePayment id={id} orderId={orderId} />
        </td>
      </tr>
    </tbody>
  ));
  if (isEmpty(payments)) {
    payments = <NoResultsRow colSpan={3} type="payments" />;
  }
  return (
    <>
      <Row>
        <Col>
          <AddPayment />
        </Col>
      </Row>
      <Table className="text-center mt-3" size="sm" bordered striped>
        <thead>
          <tr>
            <th>Type</th>
            <th>Amount</th>
            <th style={{ width: 70 }}>Edit</th>
          </tr>
        </thead>
        {payments}
      </Table>
      <hr />
    </>
  );
}

function Comments() {
  return (
    <>
      <Row>
        <Col>
          <SetComments />
        </Col>
      </Row>
      <hr />
    </>
  );
}

function OrderCreate({ match: { params: { orderId } } }) {
  const {
    Order,
    OrderProduct,
    OrderDelivery,
    Payment,
  } = useSelector((state) => state.order.read);
  const dispatch = useDispatch();
  const disabled = isEmpty(OrderProduct) || isEmpty(OrderDelivery) || isEmpty(Payment);

  useEffect(() => {
    dispatch(breadcrumbActions.add('Create Order: Page 2 of 2', `/order/create_2/${orderId}`));
  }, [dispatch, orderId]);
  useEffect(() => dispatch(read(orderId)), [orderId, dispatch]);

  if (isEmpty(Order)) {
    return '';
  }

  return (
    <>
      <Products />
      <Deliveries />
      <Comments />
      <Payments />
      <OrderWarning active={!disabled} />
      <Button color="success" onClick={() => dispatch(complete({ id: orderId, asProforma: false }))} size="lg" disabled={disabled} block>Complete & Place Order</Button>
      <div className="or__text">
        <p><span>or</span></p>
      </div>
      <Button color="primary" onClick={() => dispatch(complete({ id: orderId, asProforma: true }))} size="lg" disabled={isEmpty(OrderProduct) || isEmpty(OrderDelivery)} block>Complete & Send Pro Forma</Button>
    </>
  );
}

export default OrderCreate;
