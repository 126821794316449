import React, { useState } from 'react';
import {
  Button, Input, ListGroup, ListGroupItem, UncontrolledCollapse,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import Highlighter from 'react-highlight-words';
import ModalTrigger from 'components/modalTrigger';
import OrderProductEdit from './edit';
import { search, select, clearSearch } from '_actions/orderProduct.actions';

function ProductSearch() {
  const [searchTerm, setSearchTerm] = useState('');
  const orderId = useSelector((state) => state.order.read.Order.id);
  const dispatch = useDispatch();
  const { searchResults, selected } = useSelector((state) => state.orderProduct);
  const findProducts = () => dispatch(search(searchTerm));
  return (
    <div>
      <Input
        placeholder="Search for a product..."
        value={searchTerm}
        maxLength="30"
        onChange={(event) => setSearchTerm(event.target.value)}
        onKeyPress={(event) => event.key === 'Enter' && findProducts()}
      />
      <Button className="my-3" color="success" onClick={findProducts} disabled={!searchTerm} block>Search</Button>
      <ListGroup>
        {map(searchResults, (item, index) => {
          const productTitle = `${item.Product.title} ${item.ProductOption.title || item.ProductOption.unit_quantity}`;
          return (
            <ListGroupItem key={index}>
              <div>
                <div id={'product-' + index}>
                  <Highlighter
                    searchWords={[searchTerm]}
                    textToHighlight={productTitle}
                  />
                </div>
                <UncontrolledCollapse toggler={'product-' + index}>
                  <hr />
                  <OrderProductEdit product={selected}>
                    <Button
                      block
                      onClick={() => {
                        dispatch(select(item, orderId));
                        setSearchTerm('');
                      }}
                    >
                      Select Product
                    </Button>
                  </OrderProductEdit>
                </UncontrolledCollapse>
              </div>
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </div>
  );
}

function OrderProductAdd() {
  const dispatch = useDispatch();
  return (
    <ModalTrigger title="Add Product" trigger={<Button color="primary" onClick={() => dispatch(clearSearch())} block>Add Product</Button>}>
      <ProductSearch />
    </ModalTrigger>
  );
}

export default OrderProductAdd;
