import orderConstants from '_constants/order.constants';

export default function order(state = {}, action) {
  switch (action.type) {
    case orderConstants.LATEST_SUCCESS:
      return {
        ...state,
        latest: action.latestOrders,
      };
    case orderConstants.PAYMENT_TYPES_SUCCESS:
      return {
        ...state,
        paymentTypes: action.paymentTypes,
      };
    case orderConstants.READ_SUCCESS:
      return {
        ...state,
        read: action.order,
      };
    default:
      return {
        latest: [],
        paymentTypes: [],
        read: {
          Order: {},
          OrderProduct: [],
          OrderDelivery: [],
          Payment: [],
        },
        ...state,
      };
  }
}
