import { get } from '_services/request.service';
import orderProductConstants from '_constants/orderProduct.constants';
import blockActions from '_actions/block.actions';
import alertActions from '_actions/alert.actions';

function success(products) {
  return {
    searchResults: products,
    type: orderProductConstants.SEARCH_SUCCESS,
  };
}

function action(term) {
  return (dispatch) => {
    dispatch(blockActions.block());
    get(`/api_auth/orders/aamos/product_search/${term}.json`).then(
      (products) => {
        dispatch(success(products));
        dispatch(blockActions.clear());
      },
      (error) => {
        dispatch(blockActions.clear());
        dispatch(alertActions.error(error.toString()));
      },
    );
  };
}

export default action;
