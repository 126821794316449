import { post } from '_services/request.service';
import alertActions from '_actions/alert.actions';
import blockActions from '_actions/block.actions';

function action(payload) {
  return (dispatch) => {
    dispatch(blockActions.block());
    post('/api_auth/orders/aamos/create_order.json', payload).then(
      ({ id }) => {
        window.location = '/order/create_2/' + id;
      },
      (error) => {
        dispatch(blockActions.clear());
        dispatch(alertActions.error(error.toString()));
      },
    );
  };
}

export default action;
