import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

const CreateOrderButton = ({ organisationId = '', size }) => (
  <Button block href={'/order/create/' + organisationId} color="primary" size={size}>Create Order</Button>
);

CreateOrderButton.propTypes = {
  organisationId: PropTypes.string,
  size: PropTypes.string,
};

export default CreateOrderButton;
