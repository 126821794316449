import React from 'react';
import config from 'utilities/config';
import EditForm from 'components/editForm';

const fields = [
  {
    label: 'Title',
    inputProps: {
      type: 'text',
      name: 'title',
      disabled: true,
    },
  },
  {
    label: 'Product Code',
    inputProps: {
      type: 'text',
      name: 'product_code',
      disabled: true,
    },
  },
  {
    label: 'VAT Rate',
    inputProps: {
      type: 'select',
      name: 'vat',
      options: [
        {
          value: 0,
          text: '0%',
        },
        {
          value: config.vatRate,
          text: config.vatRate + '%',
        },
      ],
    },
  },
  {
    label: 'Price',
    inputProps: {
      type: 'number',
      name: 'price',
    },
  },
  {
    label: 'Quantity',
    inputProps: {
      type: 'number',
      name: 'quantity',
    },
  },
];

function OrderProductEdit({ product, children }) {
  return (
    <EditForm
      record={product}
      fields={fields}
      type="product"
      trigger={children}
    />
  );
}

export default OrderProductEdit;
