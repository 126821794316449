import React, { useEffect } from 'react';
import { CardBody, Button } from 'reactstrap';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import Header from 'utilities/header';
import CreateOrderButton from 'components/createOrderButton';
import alertActions from '_actions/alert.actions';
import blockActions from '_actions/block.actions';

function Dashboard() {
  const dispatch = useDispatch();
  useEffect(() => {
    const { orderComplete = false } = qs.parse(window.location.search.substring(1));
    if (orderComplete) {
      dispatch(blockActions.clear());
      dispatch(alertActions.success(<><strong>Success!</strong> Your order has been placed.</>));
    }
  }, [dispatch]);

  return (
    <div>
      <Header />
      <CardBody>
        <Button href="/organisations" color="primary" size="lg" block>
          View My Organisations
        </Button>
        <hr />
        <CreateOrderButton size="lg" />
        <hr />
        <Button href="/?logout" size="lg" block>Logout</Button>
      </CardBody>
    </div>
  );
}

export default Dashboard;
