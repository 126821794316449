import read from './read';
import alertActions from '_actions/alert.actions';
import blockActions from '_actions/block.actions';
import { closeAll } from '_actions/modal.actions';

function action(id, alertMessage) {
  return (dispatch) => {
    dispatch(blockActions.clear());
    dispatch(alertActions.success(alertMessage));
    dispatch(closeAll());
    dispatch(read(id));
  };
}

export default action;
