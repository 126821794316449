import { post } from '_services/request.service';
import rehydrate from '_actions/order/rehydrate';
import alertActions from '_actions/alert.actions';
import blockActions from '_actions/block.actions';

function action(optionIds, orderId, freeDelivery) {
  return (dispatch) => {
    dispatch(blockActions.block());
    post('/api_auth/orders/aamos/order_delivery_add.json', { optionIds, orderId, freeDelivery }).then(
      (result) => {
        if (result === false) {
          dispatch(blockActions.clear());
          dispatch(alertActions.error('Error saving delivery options'));
          return;
        }
        dispatch(rehydrate(orderId, 'Successfully saved delivery options'));
      },
      (error) => {
        dispatch(blockActions.clear());
        dispatch(alertActions.error(error.toString()));
      },
    );
  };
}

export default action;
