import modalConstants from '_constants/modal.constants';

export default function modal(state = {}, action) {
  switch (action.type) {
    case modalConstants.CLOSE_ALL:
      return {
        ...state,
        closeAll: Date.now(),
      };
    default:
      return {
        closeAll: false,
        ...state,
      };
  }
}
