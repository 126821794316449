import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  map, size, isEmpty, isEqual, uniqWith,
} from 'lodash';

function Breadcrumbs() {
  const breadcrumbs = useSelector((state) => state.breadcrumb);
  const user = useSelector((state) => state.authentication.user);
  if (isEmpty(user)) {
    return null;
  }
  return (
    <Breadcrumb className="p-0 m-0" listClassName="m-0">
      <BreadcrumbItem active><a href="/dashboard">Home</a></BreadcrumbItem>
      {map(uniqWith(breadcrumbs, isEqual), ({ title, link }, index) => (
        <BreadcrumbItem active={(index + 1) === size(breadcrumbs)} key={index}>
          <a href={link}>{title}</a>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
}

export default Breadcrumbs;
