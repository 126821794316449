import orderProductConstants from '_constants/orderProduct.constants';

export default function orderProduct(state = {}, action) {
  switch (action.type) {
    case orderProductConstants.SEARCH_SUCCESS:
      return {
        ...state,
        searchResults: action.searchResults,
      };
    case orderProductConstants.SEARCH_CLEAR:
      return {
        ...state,
        searchResults: [],
      };
    case orderProductConstants.FIND_PRICE_SUCCESS:
      return {
        ...state,
        selected: {
          ...state.selected,
          price: action.price.toFixed(2),
        },
      };
    case orderProductConstants.SELECT:
      return {
        ...state,
        selected: action.product,
      };
    default:
      return {
        searchResults: [],
        selected: {},
        ...state,
      };
  }
}
