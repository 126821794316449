import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Media,
  Row,
  Table,
} from 'reactstrap';
import { isEmpty } from 'lodash';
import { DateTime } from 'utilities/date';
import CreateOrderButton from 'components/createOrderButton';
import InternalCommentModal from 'components/InternalCommentModal';
import CustomerList from 'components/customerList';
import LatestSalesList from 'components/latestSalesList';
import { read } from '_actions/organisation.actions';
import breadcrumbActions from '_actions/breadcrumb.actions';
import { NoResultsFoundAlert } from 'utilities/alerts';
import Paginator from 'components/paginator';
import Presence from 'components/presence';

function CardHelper({ title, children, cardClassName = 'p-2' }) {
  return (
    <Card className="mb-3 card__details">
      {title && <CardHeader className="p-2">{title}</CardHeader>}
      <CardBody className={cardClassName}>
        {isEmpty(children) && <NoResultsFoundAlert />}
        {children}
      </CardBody>
    </Card>
  );
}

function ViewOrganisation({ match: { params: { id } } }) {
  const organisation = useSelector((state) => state.organisation.read);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(read(id));
    dispatch(breadcrumbActions.add('Organisations', '/organisations'));
  }, [id, dispatch]);
  const {
    Company, SpendPotential, Sector,
  } = organisation;
  useEffect(() => {
    if (Company) {
      dispatch(breadcrumbActions.add(Company.title, '/organisation/' + Company.id));
    }
  }, [Company, dispatch]);
  if (isEmpty(organisation)) {
    return null;
  }
  return (
    <span>
      <Row>
        <Col>
          <CreateOrderButton organisationId={id} />
        </Col>
        <Col>
          <InternalCommentModal id={id} />
        </Col>
      </Row>
      <hr />
      <h6>{Company.title}</h6>
      <CardHelper title="General Information" cardClassName="p-0">
        <Table className="m-0" striped borderless responsive>
          <tbody>
            <tr>
              <th>Created</th>
              <td><DateTime dateString={Company.created} /></td>
            </tr>
            <tr>
              <th>Last Checked</th>
              <td><DateTime dateString={Company.checked} /></td>
            </tr>
            <tr>
              <th>Spend Potential</th>
              <td>
                <h6 className="p-0 m-0"><Badge color="success">{SpendPotential.public_title}</Badge></h6>
              </td>
            </tr>
            <tr>
              <th>Sector</th>
              <td><Presence>{Sector.title}</Presence></td>
            </tr>
          </tbody>
        </Table>
      </CardHelper>
      <CardHelper title="Notes">
        <Paginator
          items={organisation.InternalComment}
          render={({ InternalComment, Member }) => (
            <Media key={InternalComment.id} body className="border-bottom py-1">
              <p className="m-0 mb-1">{InternalComment.comment}</p>
              <h6>
                <Badge color="primary">{Member.title}</Badge>{' '}
                <Badge color="info"><DateTime timestamp={InternalComment.added} /></Badge>
              </h6>
            </Media>
          )}
        />
      </CardHelper>
      <CardHelper title="Latest Sales">
        <LatestSalesList organisationId={id} />
      </CardHelper>
      <CardHelper title="IFS Customers">
        <CustomerList />
      </CardHelper>
    </span>
  );
}

export default ViewOrganisation;
