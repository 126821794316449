import React, { useEffect } from 'react';
import {
  UncontrolledCollapse, Card, CardHeader, CardBody, Table, CardTitle, Badge, Alert,
} from 'reactstrap';
import { map, isEmpty } from 'lodash';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { latest as fetchLatest } from '_actions/order.actions';
import { NoResultsFoundAlert } from 'utilities/alerts';
import config from 'utilities/config';
import BooleanIcon from './booleanIcon';


function StatusBadge({ Order }) {
  let content = 'Incomplete';
  if (Order.is_confirmed) {
    content = 'Confirmed';
  } else if (Order.is_proforma_order) {
    content = 'Pro Forma';
  } else if (Order.is_placed) {
    content = 'Placed';
  }
  return (
    <Badge color="info">Order Status: {content}</Badge>
  )
}

function Panel({
  Order: {
    Order,
    OrderInvoice,
    OrderProduct,
  },
}) {
  const netValue = config.currency + Order.value_net;
  return (
    <Card key={Order.id} className="card__details m-0 p-0">
      <CardHeader className="p-2 border-bottom-0" id={'panel-' + Order.id}>
        <CardTitle className="m-0 lead">
          <Badge className="text-uppercase">{Order.virtual_order_id}</Badge>{' '}
          <Badge className="text-uppercase" color="primary">{moment(Order.created).format(config.dateFormat)}</Badge>{' '}
          <Badge className="text-uppercase" color="warning">{netValue}</Badge>{' '}
          <StatusBadge Order={Order} />
        </CardTitle>
      </CardHeader>
      <UncontrolledCollapse toggler={'panel-' + Order.id}>
        <CardBody className="p-0">
          {Order.is_aamos_order && Order.is_placed === false && (
            <Alert color="info" className="text-center">
              <a href={'/order/create_2/' + Order.id}>
                Incomplete order found, click here to continue and complete.
              </a>
            </Alert>
          )}

          <Table className="m-0 text-center" striped size="sm" responsive>
            <tbody>
              <tr>
                <th>Order Number</th>
                <td>{Order.virtual_order_id}</td>
              </tr>
              <tr>
                <th>Created</th>
                <td>{moment(Order.created).format(config.datetimeFormat)}</td>
              </tr>
              <tr>
                <th>Placed</th>
                <td><BooleanIcon>{Order.is_placed}</BooleanIcon></td>
              </tr>
              <tr>
                <th>Confirmed</th>
                <td><BooleanIcon>{Number(Order.confirmed)}</BooleanIcon></td>
              </tr>
              <tr>
                <th>Invoiced</th>
                <td><BooleanIcon>{!isEmpty(OrderInvoice)}</BooleanIcon></td>
              </tr>
              <tr>
                <th>Net</th>
                <td>{netValue}</td>
              </tr>
              <tr>
                <th>Gross</th>
                <td>{config.currency}{Order.value_gro}</td>
              </tr>
              <tr>
                <th colSpan={2}>Products</th>
              </tr>
              <tr>
                <td colSpan={2} className="text-left">
                  <ul className="pl-4">
                    {map(OrderProduct, ({ title, option_title, quantity_integer }, index) => (
                      <li key={index}>
                        {quantity_integer} x {title} {option_title && <>({option_title})</>}
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </UncontrolledCollapse>
    </Card>
  );
}

function LatestSalesList({ organisationId }) {
  const orders = useSelector((state) => state.order.latest);
  const dispatch = useDispatch();
  useEffect(() => dispatch(fetchLatest(organisationId)), [dispatch, organisationId]);
  if (isEmpty(orders)) {
    return <NoResultsFoundAlert />;
  }
  return map(orders, (order, index) => <Panel key={index} Order={order} />);
}

export default LatestSalesList;
