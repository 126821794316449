import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Form, Badge, Card, CardHeader, CardBody, Alert, Table,
} from 'reactstrap';
import {
  map, isEmpty, find, forEach, startCase, first, compact, concat, includes, split, uniqBy,
} from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormInput from 'components/formInput';
import { read, readIndividuals } from '_actions/organisation.actions';
import { create } from '_actions/order.actions';
import SearchCompanyModal from 'components/searchCompanyModal';
import breadcrumbActions from '_actions/breadcrumb.actions';
import Currency from 'utilities/currency';
import BooleanIcon from 'components/booleanIcon';

function IndividualFields({
  organisation: { CompanyAddress = [] },
  individual,
  onChange,
  payload,
  type = 'delivery',
}) {
  const phoneOptions = compact(map(['primary_tel', 'secondary_tel'], (field) => (individual.Member[field] && {
    value: individual.Member[field],
    text: individual.Member[field],
  })));
  const addresses = map(concat(individual.MemberAddress, CompanyAddress), ({ Address, Address: { AddressType = {} } }) => ({
    value: Address.id,
    text: `${AddressType.title}: ${Address.address} ${Address.postcode}`,
  }));
  const getFieldName = (name) => `${type}_${name}`;
  const fields = [
    {
      field: getFieldName('email'),
      options: map(individual.MemberEmail, ({ email }) => ({
        value: email,
        text: email,
      })),
    },
    {
      field: getFieldName('name'),
      options: [{
        value: individual.Member.title,
        text: individual.Member.title,
      }],
    },
    {
      field: getFieldName('address_id'),
      label: `${startCase(type)} Address`,
      options: addresses,
    },
    {
      field: getFieldName('tel'),
      options: phoneOptions,
    },
    {
      field: getFieldName('mobile'),
      options: phoneOptions,
    },
  ];

  return map(fields, ({ field, options = [], label }, index) => {
    const optionsCount = options.length;
    return (
      <FormInput
        key={index}
        label={<span><Badge color={optionsCount ? 'primary' : 'dark'} pill>{optionsCount}</Badge> {label || startCase(field)}</span>}
        inputProps={{
          id: field,
          type: 'select',
          value: payload[field],
          options: [
            { value: '', text: 'Leave blank' },
            ...options,
          ],
          onChange,
        }}
      />
    );
  });
}

const AddressFormCard = ({ type, payload, organisation, individual, onChange, alerts }) => (
  <Card className="mt-3">
    <CardHeader>Select Delivery Details</CardHeader>
    <CardBody>
      <IndividualFields
        organisation={organisation}
        individual={individual}
        onChange={onChange}
        payload={payload}
        type={type}
      />
      {!isEmpty(alerts) && (
        <Alert color="danger" className="small mt-0">
          <ul className="m-0 p-0 pl-3">
            {map(alerts, (text, key) => <li key={key}>{text}</li>)}
          </ul>
        </Alert>
      )}
      <Alert color="warning" className="small m-0">
        If customer details are missing or out-of-date, please update them in Maxcore.
      </Alert>
    </CardBody>
  </Card>
);

function CustomerInformation({ customer: {
  is_active, obsolete, on_stop, balance, adjusted_balance, credit_limit,
} }) {
  return (
    <Table className="customer__acount__details mb-0" size="sm" striped borderless responsive>
      <tbody>
        <tr>
          <th>Active:</th>
          <td><BooleanIcon value={is_active === '1'} /></td>
        </tr>
        <tr>
          <th>Obsolete:</th>
          <td><BooleanIcon value={obsolete === 'Y'} /></td>
        </tr>
        <tr>
          <th>On Stop:</th>
          <td><BooleanIcon value={on_stop} /></td>
        </tr>
        <tr>
          <th>Balance:</th>
          <td><Currency value={balance} /></td>
        </tr>
        <tr>
          <th>Credit Limit:</th>
          <td><Currency value={credit_limit} /></td>
        </tr>
        <tr>
          <th>Adjusted Balance:</th>
          <td><Currency value={adjusted_balance} /></td>
        </tr>
        <tr>
          <th>Remaining Balance:</th>
          <td><Currency value={credit_limit - adjusted_balance} /></td>
        </tr>
      </tbody>
    </Table>
  );
}

const getAddressDefaultsFromSelectedIndividual = ({ Member, MemberAddress, MemberEmail }, setBillingDefaults) => {
  const firstAddress = first(MemberAddress);
  const types = ['delivery'];
  if (setBillingDefaults) {
    types.push('billing');
  }
  let defaults = { member_id: Member.id };
  forEach(types, (type) => {
    defaults = {
      ...defaults,
      [`${type}_email`]: !isEmpty(MemberEmail) ? first(MemberEmail).email : '',
      [`${type}_name`]: Member.title,
      [`${type}_address_id`]: !isEmpty(firstAddress) ? firstAddress.Address.id : 0,
      [`${type}_address`]: !isEmpty(firstAddress) ? firstAddress.Address.address : '',
      [`${type}_postcode`]: !isEmpty(firstAddress) ? firstAddress.Address.postcode : '',
      [`${type}_tel`]: Member.primary_tel || Member.secondary_tel,
      [`${type}_mobile`]: Member.primary_tel || Member.secondary_tel,
    };
  });
  return defaults;
};

function OrderCreate({ match: { params: { companyId } } }) {
  const organisation = useSelector((state) => state.organisation.read);
  const individuals = useSelector((state) => state.organisation.readIndividuals);
  const [individual, setIndividual] = useState({});
  const [customer, setCustomer] = useState({});
  const [payload, setPayload] = useState({});
  const customersFound = !isEmpty(organisation.Customer);
  const noCustomersFound = !customersFound;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(breadcrumbActions.add('Create Order: Page 1 of 2', `/order/create/${companyId || ''}`));
  }, [dispatch, companyId]);

  const findAddress = useCallback((addressId) => {
    const { Address } = find(concat(
      individual.MemberAddress,
      organisation.CompanyAddress,
    ), (address) => address.Address.id === addressId);
    return Address;
  }, [individual.MemberAddress, organisation.CompanyAddress]);

  const setField = useCallback((id, value) => {
    const newState = { [id]: value };
    if (id === 'encore_customer_index') {
      setCustomer(find(
        organisation.Customer, (ifsCustomer) => ifsCustomer.id === value,
      ));
    }
    if (includes(id, 'address_id') && value) {
      const address = findAddress(value);
      const type = first(split(id, '_'));
      newState[`${type}_address`] = address.address;
      newState[`${type}_postcode`] = address.postcode;
    }
    setPayload((state) => ({ ...state, ...newState }));
  }, [findAddress, organisation.Customer]);

  const onChange = ({ target: { id, value } }) => setField(id, value);

  const fetchCompany = useCallback(() => {
    dispatch(read(companyId));
    dispatch(readIndividuals(companyId));
  }, [companyId, dispatch]);

  useEffect(() => {
    if (companyId) {
      setPayload((state) => ({ ...state, company_id: companyId }));
      fetchCompany();
    }
  }, [companyId, setPayload, fetchCompany]);

  useEffect(() => {
    if (isEmpty(organisation)) {
      return;
    }
    setField('company', organisation.Company.title);
    if (!isEmpty(first(organisation.Customer))) {
      setField('encore_customer_index', first(organisation.Customer).id);
    }
  }, [organisation, setField, dispatch]);

  const selectIndividual = useCallback((selectedIndividual) => {
    setIndividual(selectedIndividual);
    const defaultAddressValues = getAddressDefaultsFromSelectedIndividual(selectedIndividual, noCustomersFound);
    setPayload((state) => ({ ...state, ...defaultAddressValues }));
  }, [noCustomersFound]);

  useEffect(() => {
    if (!isEmpty(individuals)) {
      selectIndividual(first(individuals));
    }
  }, [individuals, selectIndividual]);

  if (isEmpty(payload.company_id)) {
    return (
      <span>
        <h6 className="text-center">Please select an organisation</h6>
        <SearchCompanyModal buttonProps={{ color: 'primary' }} />
      </span>
    );
  }

  const requiredDeliveryFieldsUnset =  isEmpty(payload.delivery_address_id) || isEmpty(payload.delivery_email);
  const requiredBillingFieldsUnset = isEmpty(payload.billing_address_id) || isEmpty(payload.billing_email);

  const formDisabled = (
    isEmpty(individual)
    || (customersFound && isEmpty(payload.encore_customer_index))
    || requiredDeliveryFieldsUnset
    || (noCustomersFound && requiredBillingFieldsUnset)
  );

  return (
    <span>
      <Form>
        {payload.company && (
          <Card className="mb-3">
            <CardHeader>Select Organisation</CardHeader>
            <CardBody>
              <p className="mb-1">{payload.company}</p>
              <SearchCompanyModal title="Change Organisation" buttonProps={{ color: 'primary', block: true }} />
              <Button className="reload__button" onClick={fetchCompany}>
                Reload <FontAwesomeIcon icon="sync" fixedWidth />
              </Button>
            </CardBody>
          </Card>
        )}
        <Card>
          <CardHeader>Select Individual</CardHeader>
          <CardBody>
            <FormInput
              label="Individual"
              inputProps={{
                id: 'member_id',
                type: 'select',
                options: map(individuals, ({ Member: { id, title } } = {}) => ({
                  value: id,
                  text: title,
                })),
                invalid: isEmpty(individual),
                onChange: (e) => {
                  onChange(e);
                  selectIndividual(find(individuals, ({ Member }) => Member.id === e.target.value));
                },
              }}
            />
            {isEmpty(individuals) && <Alert color="danger">No individuals found for this organisation. Please assign them in Maxcore.</Alert>}
            {customersFound ? (
              <FormInput
                label="IFS Account"
                inputProps={{
                  id: 'encore_customer_index',
                  type: 'select',
                  options: uniqBy(map(organisation.Customer, ({ id, name }) => ({
                    value: id,
                    text: name,
                  })), 'value'),
                  invalid: isEmpty(payload.encore_customer_index),
                  onChange,
                }}
              />
            ) : <Alert color="info">Lead managed organisation. Please choose billing and delivery addresses below.</Alert>}
            {customersFound && <CustomerInformation customer={customer} />}
          </CardBody>
        </Card>
        {!isEmpty(individual) && (
          <>
            <AddressFormCard
              type="delivery"
              payload={payload}
              organisation={organisation}
              individual={individual}
              onChange={onChange}
              alerts={requiredDeliveryFieldsUnset ? compact([
                isEmpty(payload.delivery_address_id) && 'An address is required',
                isEmpty(payload.delivery_email) && 'An email is required',
                customersFound && isEmpty(payload.encore_customer_index) && 'IFS Account Required',
              ]) : []}
            />
            {noCustomersFound && (
              <AddressFormCard
                type="billing"
                payload={payload}
                organisation={organisation}
                individual={individual}
                onChange={onChange}
                alerts={requiredBillingFieldsUnset ? compact([
                  isEmpty(payload.billing_address_id) && 'An address is required',
                  isEmpty(payload.billing_email) && 'An email is required',
                ]) : []}
              />
            )}
          </>
        )}
      </Form>
      <hr />
      <Button
        onClick={() => dispatch(create(payload))}
        color="primary"
        size="lg"
        disabled={formDisabled}
        block
      >
        Create Order
      </Button>
    </span>
  );
}

OrderCreate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      companyId: PropTypes.string,
    }),
  }),
};

export default OrderCreate;
