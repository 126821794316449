import request from 'utilities/request';

function callback({ data, error }) {
  if (error) {
    return Promise.reject(error.message);
  }
  return data.json;
}

function get(endpoint, payload) {
  return request.get(endpoint, {
    params: payload,
  }).then(callback);
}

function post(endpoint, payload) {
  return request.post(endpoint, payload)
    .then(callback);
}

function remove(endpoint, id) {
  return request.delete(endpoint, { data: { id } })
    .then(callback);
}

export {
  get,
  post,
  remove,
};
