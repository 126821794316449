import userConstants from '_constants/user.constants';
import config from 'utilities/config';

const user = JSON.parse(localStorage.getItem(config.localStorageKey));
const initialState = user ? { loggedIn: true, user } : {};

export default function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: true,
        user: action.user,
      };
    case userConstants.LOGIN_SUCCESS:
    case userConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        updateSuccess: true,
        user: action.user,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loggedIn: false,
        user: {},
      };
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}
