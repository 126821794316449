import React from 'react';
import { Alert } from 'reactstrap';

function NoResultsFoundAlert({ text = 'No results found' }) {
  return <Alert color="info" className="m-0 p-2">{text}</Alert>;
}

export {
  NoResultsFoundAlert,
};
