import userConstants from '_constants/user.constants';
import userService from '_services/user.service';
import login from './user/login';

function logout() {
  userService.logout();
  return {
    type: userConstants.LOGOUT,
  };
}

export default {
  login,
  logout,
};
