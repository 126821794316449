import userConstants from '_constants/user.constants';
import userService from '_services/user.service';
import blockActions from '_actions/block.actions';
import alertActions from '_actions/alert.actions';
import history from '_helpers/history';

function request(user) {
  return {
    type: userConstants.LOGIN_REQUEST,
    user,
  };
}
function success(user) {
  return {
    type: userConstants.LOGIN_SUCCESS,
    user,
  };
}
function failure(error) {
  return {
    type: userConstants.LOGIN_FAILURE,
    error,
  };
}

function action(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));
    userService.login(username, password).then(
      (user) => {
        dispatch(success(user));
        history.push('/dashboard');
        dispatch(blockActions.clear());
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.toString()));
        dispatch(blockActions.clear());
      },
    );
  };
}

export default action;
