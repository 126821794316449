import { get } from '_services/request.service';
import organisationConstants from '_constants/organisation.constants';
import alertActions from '_actions/alert.actions';
import blockActions from '_actions/block.actions';

function success(organisations) {
  return {
    organisations,
    filtered: organisations,
    type: organisationConstants.LIST_SUCCESS,
  };
}

function failure() {
  return {
    type: organisationConstants.LIST_FAILURE,
  };
}

function action() {
  return (dispatch) => {
    dispatch(blockActions.block());
    get('/api_auth/orders/aamos/organisations.json').then(
      (companies) => {
        dispatch(blockActions.clear());
        dispatch(success(companies));
      },
      (error) => {
        dispatch(blockActions.clear());
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error));
      },
    );
  };
}

export default action;
