import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Alert } from 'reactstrap';
import {
  map, sumBy, isEmpty, first,
} from 'lodash';
import { paymentTypes as getPaymentTypes, submitRelatedRecord } from '_actions/order.actions';
import ModalTrigger from 'components/modalTrigger';
import FormInput from 'components/formInput';
import config from 'utilities/config';
import OrderWarning, { CreditWarning } from 'components/orderWarning';

function AddPayment() {
  const {
    Order: { value_gro, id: orderId },
    Payment,
    OrderProduct,
    OrderDelivery,
  } = useSelector((state) => state.order.read);
  const { paymentTypes } = useSelector((state) => state.order);
  const dispatch = useDispatch();
  const [payload, setPayload] = useState({});

  useEffect(() => {
    const toPay = Number(value_gro) - sumBy(Payment, ({ amount }) => Number(amount));
    setPayload((state) => ({
      ...state,
      shop_order_id: orderId,
      amount: toPay.toFixed(2),
    }));
  }, [orderId, value_gro, Payment]);

  useEffect(() => dispatch(getPaymentTypes()), [orderId, dispatch]);

  useEffect(() => setPayload((state) => ({
    ...state,
    shop_payment_type_id: !isEmpty(paymentTypes) && first(paymentTypes).id,
  })), [paymentTypes]);

  const onChange = ({ target: { name, value } }) => setPayload({ ...payload, [name]: value });
  const trigger = <Button color="primary" block disabled={isEmpty(OrderProduct) || isEmpty(OrderDelivery)}>Add Payment</Button>;
  const isCreditPayment = Number(payload.shop_payment_type_id) === config.creditAccountTypeId;
  const buttonText = isCreditPayment ? 'Save, Complete & Place Order' : 'Save';

  return (
    <ModalTrigger title="Add Payment" trigger={trigger}>
      <Alert color="info">If this order will be submitted as a Pro Forma, please do not add a payment.</Alert>
      <Form>
        <FormInput
          label="Type"
          inputProps={{
            type: 'select',
            name: 'shop_payment_type_id',
            value: payload.shop_payment_type_id,
            options: map(paymentTypes, ({ id, title }) => ({
              text: title,
              value: id,
            })),
            onChange,
          }}
        />
        <FormInput
          label="Amount"
          inputProps={{
            type: 'number',
            name: 'amount',
            placeholder: 'Please enter an amount...',
            value: payload.amount,
            onChange,
          }}
        />
      </Form>
      {isCreditPayment && <CreditWarning amount={payload.amount} />}
      <OrderWarning active={isCreditPayment} />
      {isCreditPayment && <Alert color="danger"><strong>Important!</strong> Once a credit payment is added this order will be completed and submitted to Maxcore.</Alert>}
      <Button color="success" onClick={() => dispatch(submitRelatedRecord(payload, 'payment'))} block>{buttonText}</Button>
    </ModalTrigger>
  );
}

export default AddPayment;
