/* eslint no-unused-vars: ["warn", { "varsIgnorePattern": "Moment" }] */
import React, { useEffect } from 'react';

import 'scss/custom.scss';
import { Alert, Card } from 'reactstrap';
import jwt_decode from 'jwt-decode';
import Moment from 'react-moment'; // this is included so the moment.duration call works
import moment from 'moment';
import { Router } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import config from 'utilities/config';
import alertActions from '_actions/alert.actions';
import history from '_helpers/history';
import Routes from 'utilities/routes';
import Footer from 'components/footer';
import Breadcrumbs from 'components/breadcrumbs';
import Loader from 'utilities/loader';

function checkUserLoggedIn() {
  setInterval(() => {
    const User = JSON.parse(localStorage.getItem(config.localStorageKey));
    if (User) {
      const decoded = jwt_decode(User.jwt_token);
      const duration = decoded.exp - moment().unix();
      if (duration < 1) {
        window.location = '/?expired';
      }
    }
  }, 1000);
}

function Login() {
  const dispatch = useDispatch();
  const { alert, block } = useSelector((state) => ({
    alert: state.alert,
    block: state.block,
  }));
  const { message } = alert;
  useEffect(() => {
    if (message) {
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 7000);
    }
  }, [message, dispatch]);
  useEffect(() => {
    checkUserLoggedIn();
    history.listen(() => {
      dispatch(alertActions.clear());
    });
  }, [dispatch]);
  return (
    <Router history={history}>
      <div>
        {alert.message && <Alert className="text-center top__alert" color={alert.type}>{alert.message}</Alert>}
        {block && (
          <div>
            <div className="blocker" />
            <div className="blocker--loader">
              <Loader text="Loading..." />
            </div>
          </div>
        )}
        <Breadcrumbs />
        <Card body>
          <Routes />
          <Footer />
        </Card>
      </div>
    </Router>
  );
}

export default Login;
