import blockConstants from '_constants/block.constants';

export default function block(state = false, action) {
  switch (action.type) {
    case blockConstants.BLOCK:
      return true;
    case blockConstants.CLEAR:
      return false;
    default:
      return state;
  }
}
