import { get } from '_services/request.service';
import orderDeliveryConstants from '_constants/orderDelivery.constants';
import alertActions from '_actions/alert.actions';
import blockActions from '_actions/block.actions';

function success(options) {
  return {
    options,
    type: orderDeliveryConstants.DELIVERY_OPTIONS_SUCCESS,
  };
}

function action(orderId) {
  return (dispatch) => {
    dispatch(blockActions.block());
    get(`/api_auth/orders/aamos/delivery_options/${orderId}.json`).then(
      (options) => {
        dispatch(blockActions.clear());
        dispatch(success(options));
      },
      (error) => {
        dispatch(blockActions.clear());
        dispatch(alertActions.error(error.toString()));
      },
    );
  };
}

export default action;
