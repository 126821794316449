import { get } from '_services/request.service';
import organisationConstants from '_constants/organisation.constants';
import alertActions from '_actions/alert.actions';
import blockActions from '_actions/block.actions';

function success(individuals) {
  return {
    individuals,
    type: organisationConstants.READ_INDIVIDUALS_SUCCESS,
  };
}

function failure() {
  return {
    type: organisationConstants.READ_INDIVIDUALS_FAILURE,
  };
}

function action(organisationId) {
  return (dispatch) => {
    dispatch(blockActions.block());
    get(`/api_auth/orders/aamos/individuals/${organisationId}.json`).then(
      (individuals) => {
        dispatch(blockActions.clear());
        dispatch(success(individuals));
      },
      (error) => {
        dispatch(blockActions.clear());
        dispatch(alertActions.error(error.toString()));
        dispatch(failure(error));
      },
    );
  };
}

export default action;
