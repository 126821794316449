import { combineReducers } from 'redux';
import authentication from './authentication.reducer';
import alert from './alert.reducer';
import block from './block.reducer';
import organisation from './organisation.reducer';
import order from './order.reducer';
import orderProduct from './orderProduct.reducer';
import orderDelivery from './orderDelivery.reducer';
import breadcrumb from './breadcrumb.reducer';
import modal from './modal.reducer';

const rootReducer = combineReducers({
  authentication,
  alert,
  block,
  breadcrumb,
  organisation,
  order,
  orderProduct,
  orderDelivery,
  modal,
});

export default rootReducer;
