import orderProductConstants from '_constants/orderProduct.constants';

function action() {
  return (dispatch) => {
    dispatch({
      type: orderProductConstants.SEARCH_CLEAR,
    });
  };
}

export default action;
