import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import config from './config';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    render={(props) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      localStorage.getItem(config.localStorageKey) ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
    )}
  />
);

export default PrivateRoute;
