import React from 'react';
import { Alert } from 'reactstrap';
import { useSelector } from 'react-redux';
import { last, filter } from 'lodash';
import Currency from 'utilities/currency';

const OrderWarning = ({ active = false }) => ((
  active ? <Alert color="danger"><strong>Important!</strong> Your customer will receive email notification of this order once placed.</Alert> : null
));

const rates = [
  {
    level: 0,
    alertStyle: 'success',
    creditWarning: null,
  },
  {
    level: 50,
    alertStyle: 'warning',
    creditWarning: <li><strong>Warning! Credit limit utilization higher than 50.</strong></li>,
  },
  {
    level: 90,
    alertStyle: 'danger',
    creditWarning: <li><strong>Warning! Credit limit utilization higher than 90%.<br />Please liase with credit control before further orders are placed.</strong></li>,
  },
  {
    level: 100,
    alertStyle: 'danger',
    creditWarning: <li><strong>Warning! Credit limit utilization higher than 100%.<br />Please liase with credit control before further orders are placed.</strong></li>,
  },
];

export const CreditWarning = ({ amount }) => {
  const { Customer } = useSelector((state) => state.order.read);
  const creditUsedPercent = ((1 - (Customer.credit_limit - Customer.balance - amount) / Customer.credit_limit) * 100).toFixed(0);
  const { alertStyle, creditWarning = null } = last(filter(rates, (rate) => creditUsedPercent >= rate.level && rate)) || {};
  return (
    <>
      {Customer.on_stop && <Alert color="danger"><strong>Warning!</strong> This customer is currently on stop.</Alert>}
      <Alert color={alertStyle}>
        <ul className="list-unstyled">
          <li><strong>Credit Limit:</strong> <Currency value={Customer.credit_limit} /></li>
          <li><strong>Current Balance:</strong> <Currency value={Customer.balance} /></li>
          <li><strong>Available Credit:</strong> <Currency value={Customer.credit_limit - Customer.balance} /></li>
          <li><strong>Credit Utilization:</strong> {creditUsedPercent}%</li>
          {creditWarning && <><hr />{creditWarning}</>}
        </ul>
      </Alert>
    </>
  );
};

export default OrderWarning;
