import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import Header from 'utilities/header';

const NotFound = (props) => (
  <div>
    <Header />
    <Card>
      <CardBody className="text-center">
        <div className="display-1 d-block">404</div>
        <div className="mb-4 lead">Error: Page Not Found</div>
        <Button color="primary" onClick={() => props.history.goBack()}>
          Click here to return to the previous page
        </Button>
      </CardBody>
    </Card>
  </div>
);

export default NotFound;
