import React from 'react';
import { Input, FormGroup, Label } from 'reactstrap';
import { map, omit } from 'lodash';

function FormInput({ label, inputProps, inputProps: { options } }) {
  return (
    <FormGroup>
      {label && <Label for={inputProps.id}>{label}</Label>}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Input {...omit(inputProps, 'options')}>
        {options && map(options, ({ value, text }, index) => (
          <option key={index} value={value}>{text}</option>
        ))}
      </Input>
    </FormGroup>
  );
}

export default FormInput;
