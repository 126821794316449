import React from 'react';
import { Button } from 'reactstrap';
import config from 'utilities/config';
import EditForm from 'components/editForm';

const fields = [
  {
    label: 'Price',
    inputProps: {
      type: 'number',
      name: 'price',
    },
  },
  {
    label: 'VAT Rate',
    inputProps: {
      type: 'select',
      name: 'vat',
      options: [
        {
          value: 0,
          text: '0%',
        },
        {
          value: config.vatRate,
          text: config.vatRate + '%',
        },
      ],
    },
  },
];

function EditDelivery({ delivery }) {
  return (
    <EditForm
      record={delivery}
      fields={fields}
      type="delivery"
      trigger={<Button color="info" block size="sm">Edit</Button>}
    />
  );
}

export default EditDelivery;
