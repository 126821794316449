import axios from 'axios';
import config from './config';

function updateJwtToken(token) {
  const user = JSON.parse(localStorage.getItem(config.localStorageKey)) || {};
  user.jwt_token = token;
  localStorage.setItem(config.localStorageKey, JSON.stringify(user));
}

axios.interceptors.request.use((axiosConfig) => {
  const tempConfig = axiosConfig;
  tempConfig.baseURL = config.apiUrl;
  const user = JSON.parse(localStorage.getItem(config.localStorageKey));
  if (user && user.jwt_token) {
    tempConfig.headers.Authorization = `Bearer ${user.jwt_token}`;
  }
  return tempConfig;
},
(error) => Promise.reject(error));

axios.interceptors.response.use((response) => {
  if (response.headers.authorization) {
    updateJwtToken(response.headers.authorization);
  }
  return response;
}, (error) => {
  const { response: { status } = {} } = error;
  if (status === 500 || status === 403) {
    window.location = '/?expired';
  } else {
    return Promise.reject(error);
  }
  return true;
});

export default axios;
