import breadcrumbConstants from '_constants/breadcrumb.constants';

export default function breadcrumb(state = [], { type, link, title }) {
  switch (type) {
    case breadcrumbConstants.ADD:
      state.push({
        title,
        link,
      });
      return state;
    case breadcrumbConstants.RESET:
      return [];
    default:
      return state;
  }
}
