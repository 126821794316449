import orderConstants from '_constants/order.constants';
import { get } from '_services/request.service';
import alertActions from '_actions/alert.actions';
import blockActions from '_actions/block.actions';

function success(paymentTypes) {
  return {
    paymentTypes,
    type: orderConstants.PAYMENT_TYPES_SUCCESS,
  };
}

function action() {
  return (dispatch) => {
    dispatch(blockActions.block());
    get('/api_auth/orders/aamos/payment_types.json').then(
      (paymentTypes) => {
        dispatch(blockActions.clear());
        dispatch(success(paymentTypes));
      },
      (error) => {
        dispatch(blockActions.clear());
        dispatch(alertActions.error(error.toString()));
      },
    );
  };
}

export default action;
