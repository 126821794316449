import React, { useState } from 'react';
import blockActions from '_actions/block.actions';
import ModalTrigger from 'components/modalTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { get } from '_services/request.service';
import { Table } from 'reactstrap';
import { map } from 'lodash';
import { DateTime } from 'utilities/date';

const urls = {
  product_information: '/api_auth/orders/aamos/product_information.json',
};

export default function InformationModal({ product: { product_code } }) {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const trigger = (active) => {
    dispatch(blockActions.block());
    if (!active) {
      return;
    }

    get(urls.product_information, {
      product_code,
    }).then((response) => {
      dispatch(blockActions.clear());
      if (response) {
        setData(response);
      }
    });
  };

  return (
    <ModalTrigger title="Product Information" trigger={<FontAwesomeIcon onClick={trigger} icon="info-circle" /> }>
      {data && (
        <>
          <StockInformation data={data.StockLevelsByLocation[0]} />
          <hr />
          <PurchaseOrders data={data.ReleasedPurchaseOrders} />
          <hr />
          <PriceLists data={data.CustomerPrice} />
        </>
      )}
    </ModalTrigger>
  );
}

function StockInformation({ data: { location, instock, onorder, allocated, unavailable, free, available }}) {
  return (
    <div>
      <strong>Stock Information</strong>
      <Table size="sm" striped>
        <tbody>
          <tr>
            <th>Location</th>
            <td>{location}</td>
          </tr>
          <tr>
            <th>In Stock</th>
            <td>{instock}</td>
          </tr>
          <tr>
            <th>On Order</th>
            <td>{onorder}</td>
          </tr>
          <tr>
            <th>Allocated</th>
            <td>{allocated}</td>
          </tr>
          <tr>
            <th>Unavailable</th>
            <td>{unavailable}</td>
          </tr>
          <tr>
            <th>Free Stock</th>
            <td>{free}</td>
          </tr>
          <tr>
            <th>Available</th>
            <td>{available}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

function PurchaseOrders({ data }) {
  return (
    <div>
      <strong>Released Purchase Orders</strong>
      <Table size="sm" striped>
        <thead>
          <tr>
            <th>Site</th>
            <th>PO #</th>
            <th>Quantity</th>
            <th>Order Date</th>
            <th>Planned Receipt Date</th>
          </tr>
        </thead>
        <tbody>
          {map(data, (row, index) => (
            <tr key={index}>
              <td>{row.site}</td>
              <td>{row.purchase_order_number}</td>
              <td>{row.quantity}</td>
              <td><DateTime dateString={row.order_date} /></td>
              <td><DateTime dateString={row.planned_receipt_date} /></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

function PriceLists({ data }) {
  return (
    <div>
      <strong>Price Lists</strong>
      <Table size="sm" striped>
        <thead>
          <tr>
            <th>Price List</th>
            <th>Quantity</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {map(data, ({ pricelist, minqty, price }, index) => (
            <tr key={index}>
              <td>{pricelist}</td>
              <td>{minqty}+</td>
              <td>{price}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
