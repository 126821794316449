import orderProductConstants from '_constants/orderProduct.constants';
import { findPrice } from '_actions/orderProduct.actions';
import blockActions from '_actions/block.actions';

function action({ Product, ProductOption }, orderId) {
  return (dispatch) => {
    dispatch(blockActions.block());
    dispatch({
      type: orderProductConstants.SELECT,
      product: {
        shop_order_id: orderId,
        shop_product_id: Product.id,
        shop_product_option_id: ProductOption.id,
        quantity: ProductOption.minimum_order_quantity,
        vat: Product.vat,
        title: Product.title,
        option_title: ProductOption.title || ProductOption.unit_quantity,
        product_code: ProductOption.product_code,
      },
    });
    dispatch(findPrice(ProductOption.id, ProductOption.minimum_order_quantity));
  };
}

export default action;
