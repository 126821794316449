import breadcrumbConstants from '_constants/breadcrumb.constants';

function add(title, link) {
  return {
    type: breadcrumbConstants.ADD,
    title,
    link,
  };
}

function reset() {
  return { type: breadcrumbConstants.RESET };
}

export default {
  add,
  reset,
};
