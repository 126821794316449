import blockConstants from '_constants/block.constants';

function block() {
  return { type: blockConstants.BLOCK };
}

function clear() {
  return { type: blockConstants.CLEAR };
}

export default {
  block,
  clear,
};
