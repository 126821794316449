import { get } from '_services/request.service';
import rehydrate from '_actions/order/rehydrate';
import alertActions from '_actions/alert.actions';
import blockActions from '_actions/block.actions';

function action(orderId) {
  return (dispatch) => {
    dispatch(blockActions.block());
    get(`/api_auth/orders/aamos/order_delivery_add_custom/${orderId}.json`).then(
      (result) => {
        if (result === false) {
          dispatch(blockActions.clear());
          dispatch(alertActions.error('Error adding custom delivery option'));
          return;
        }
        dispatch(rehydrate(orderId, 'Successfully added custom delivery option'));
      },
      (error) => {
        dispatch(blockActions.clear());
        dispatch(alertActions.error(error.toString()));
      },
    );
  };
}

export default action;
