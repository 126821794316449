import orderDeliveryConstants from '_constants/orderDelivery.constants';

export default function orderDelivery(state = {}, action) {
  switch (action.type) {
    case orderDeliveryConstants.DELIVERY_OPTIONS_SUCCESS:
      return {
        ...state,
        options: action.options,
      };
    default:
      return {
        options: [],
        ...state,
      };
  }
}
