import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './privateRoute';
import LoginPage from 'pages/login';
import NotFound from 'pages/notFound';
import Dashboard from 'pages/dashboard';
import ListOrganisations from 'pages/organisations/list';
import ViewOrganisation from 'pages/organisations/view';
import OrderCreate from 'pages/order/create';
import OrderCreatePageTwo from 'pages/order/create_2';

function Routes() {
  return (
    <Switch>
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/organisations" component={ListOrganisations} />
      <PrivateRoute path="/organisation/:id" component={ViewOrganisation} />
      <PrivateRoute path="/order/create/:companyId?" component={OrderCreate} />
      <PrivateRoute path="/order/create_2/:orderId?" component={OrderCreatePageTwo} />
      <Route exact path="/" component={LoginPage} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default Routes;
