import { post } from '_services/request.service';
import organisationConstants from '_constants/organisation.constants';
import blockActions from '_actions/block.actions';
import alertActions from '_actions/alert.actions';
import read from './read';
import { closeAll } from '_actions/modal.actions';

function success() {
  return {
    type: organisationConstants.SUBMIT_INTERNAL_COMMENT_SUCCESS,
  };
}

function failure() {
  return {
    type: organisationConstants.SUBMIT_INTERNAL_COMMENT_FAILURE,
  };
}

function action(internalComment) {
  return (dispatch) => {
    dispatch(blockActions.block());
    post('/api_auth/orders/aamos/add_internal_comment.json', internalComment).then(
      () => {
        dispatch(alertActions.success('Successfully saved comment'));
        dispatch(success());
        dispatch(closeAll());
        dispatch(read(internalComment.company_id));
      },
      (error) => {
        dispatch(blockActions.clear());
        dispatch(alertActions.error(error.toString()));
        dispatch(failure());
      },
    );
  };
}

export default action;
