import React from 'react';
import moment from 'moment';
import config from './config';

function DateTime({ timestamp = 0, dateString = '', format = config.datetimeFormat }) {
  if (dateString === '0000-00-00 00:00:00') {
    return <span>Never</span>;
  }
  const dateMoment = dateString ? moment(dateString) : moment.unix(timestamp);
  return <span>{dateMoment.format(format)}</span>;
}

function Date({ timestamp, dateString, format = config.dateFormat }) {
  return <DateTime timestamp={timestamp} dateString={dateString} format={format} />;
}

export {
  DateTime,
  Date,
};
