import React from 'react';
import { Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function BooleanIcon({ value, children }) {
  const checkValue = value || children;

  return (
    <Badge color={checkValue ? 'success' : 'danger'}>
      <FontAwesomeIcon icon={checkValue ? 'check' : 'times'} size="lg" />
    </Badge>
  );
}

export default BooleanIcon;
