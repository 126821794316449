import { get } from '_services/request.service';
import orderProductConstants from '_constants/orderProduct.constants';
import blockActions from '_actions/block.actions';
import alertActions from '_actions/alert.actions';

function success(price) {
  return {
    price,
    type: orderProductConstants.FIND_PRICE_SUCCESS,
  };
}

function action(optionId, quantity) {
  return (dispatch) => {
    dispatch(blockActions.block());
    get(`/api_auth/orders/aamos/product_prices/${optionId}/${quantity}.json`).then(
      (price) => {
        dispatch(success(price));
        dispatch(blockActions.clear());
      },
      (error) => {
        dispatch(blockActions.clear());
        dispatch(alertActions.error(error.toString()));
      },
    );
  };
}

export default action;
