import React, { useState, useEffect } from 'react';
import {
  Pagination, PaginationItem, PaginationLink,
} from 'reactstrap';
import {
  map, size, ceil, range, chunk, isEmpty,
} from 'lodash';
import { NoResultsFoundAlert } from 'utilities/alerts';

function Paginator({ items, itemsPerPage = 10, render }) {
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalItems, setTotalItems] = useState(1);
  useEffect(() => {
    setLastPage(ceil(totalItems / itemsPerPage));
  }, [totalItems, itemsPerPage]);
  useEffect(() => {
    setTotalItems(size(items));
  }, [items]);
  if (isEmpty(items)) {
    return <NoResultsFoundAlert />;
  }
  const content = map(chunk(items, itemsPerPage)[page - 1], (item) => render(item));
  if (size(items) <= itemsPerPage) {
    return content;
  }
  return (
    <div>
      {content}
      <div className="paginator">
        <Pagination listClassName="justify-content-center">
          {map(range(0, lastPage), (pageNumber) => (
            <PaginationItem active={(pageNumber + 1) === page} key={pageNumber}>
              <PaginationLink onClick={() => setPage(pageNumber + 1)}>
                {pageNumber + 1}
              </PaginationLink>
            </PaginationItem>
          ))}
        </Pagination>
      </div>
    </div>
  );
}

export default Paginator;
