export default {
  LIST_REQUEST: 'ORGANISATION_LIST',
  LIST_SUCCESS: 'ORGANISATION_LIST_SUCCESS',
  LIST_FAILURE: 'ORGANISATION_LIST_FAILURE',
  LIST_FILTER_SUCCESS: 'ORGANISATION_LIST_FILTER_SUCCESS',
  READ_SUCCESS: 'ORGANISATION_READ_SUCCESS',
  READ_FAILURE: 'ORGANISATION_READ_FAILURE',
  READ_INDIVIDUALS_SUCCESS: 'ORGANISATION_READ_INDIVIDUALS_SUCCESS',
  READ_INDIVIDUALS_FAILURE: 'ORGANISATION_READ_INDIVIDUALS_FAILURE',
  ADD_COMMENT: 'ORGANISATION_ADD_COMMENT',
  SUBMIT_INTERNAL_COMMENT_SUCCESS: 'ORGANISATION_INTERNAL_COMMENT_SUBMIT_SUCCESS',
  SUBMIT_INTERNAL_COMMENT_FAILURE: 'ORGANISATION_INTERNAL_COMMENT_SUBMIT_FAILURE',
};
