import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Alert,
  Badge,
  Col,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
  UncontrolledCollapse,
} from 'reactstrap';
import { map, isEmpty, size } from 'lodash';
import Highlighter from 'react-highlight-words';
import { list as fetchList, filter } from '_actions/organisation.actions';

function FilterResults({ filtered, search, callback }) {
  if (isEmpty(filtered) && !isEmpty(search)) {
    return <Alert color="danger" className="m-0">No results found for <strong>{search}</strong>.</Alert>;
  }
  return (
    <ListGroup>
      {map(filtered, (record) => {
        const { id, title } = record;
        return (
          <ListGroupItem key={id}>
            <div>
              <div id={'organisation-' + id}>
                <Highlighter
                  searchWords={[search]}
                  textToHighlight={title}
                />
              </div>
              <UncontrolledCollapse toggler={'organisation-' + id}>
                <hr />
                {callback(record)}
              </UncontrolledCollapse>
            </div>
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
}

function FilterOrganisations({ callback }) {
  const { filtered, organisations } = useSelector(({ organisation }) => {
    return {
      filtered: organisation.filtered,
      organisations: organisation.list,
    };
  });
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  useEffect(() => dispatch(fetchList()), [dispatch]);
  useEffect(() => dispatch(filter(search, organisations)), [search, organisations, dispatch]);

  return (
    <div>
      <Input
        placeholder="Search..."
        value={search}
        maxLength="30"
        onChange={(e) => setSearch(e.target.value)}
      />
      <Row>
        <Col>
          {search && <Badge>{size(filtered)} filtered.</Badge>}
        </Col>
        <Col>
          <Badge color="info" className="float-right">{size(organisations)} results found.</Badge>
        </Col>
      </Row>
      <hr />
      <FilterResults filtered={filtered} search={search} callback={callback} />
    </div>
  );
}

export default FilterOrganisations;
