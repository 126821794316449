import { post } from '_services/request.service';
import rehydrate from './rehydrate';
import blockActions from '_actions/block.actions';
import alertActions from '_actions/alert.actions';
import config from 'utilities/config';

function action(payload, relationType) {
  return (dispatch) => {
    dispatch(blockActions.block());
    const type = payload.id ? 'edit' : 'add';
    post(`/api_auth/orders/aamos/order_${relationType}_${type}.json`, payload).then(
      (result) => {
        if (result === false) {
          dispatch(blockActions.clear());
          dispatch(alertActions.error(`Error saving ${relationType}`));
          return;
        }
        if (Number(payload.shop_payment_type_id) === config.creditAccountTypeId) {
          dispatch(alertActions.success('Successfully completed order'));
          window.setTimeout(() => {
            window.location = '/dashboard';
          }, 2000);
          return;
        }
        dispatch(rehydrate(payload.shop_order_id || payload.id, `Successfully ${type}ed ${relationType}`));
      },
      (error) => {
        dispatch(blockActions.clear());
        dispatch(alertActions.error(error.toString()));
      },
    );
  };
}

export default action;
