import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSearch, faSpinner, faCheck, faTimes, faSync, faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import Layout from './layout/layout';
import * as serviceWorker from './serviceWorker';
import store from './_helpers/store';
import 'react-app-polyfill/ie11';

library.add({
  faSearch, faSpinner, faCheck, faTimes, faSync, faInfoCircle
});

ReactDOM.render(
  <Provider store={store}>
    <Layout />
  </Provider>,
  document.getElementById('root'),
);
serviceWorker.unregister();
