import { isEmpty } from 'lodash';
import { get } from '_services/request.service';
import orderConstants from '_constants/order.constants';
import alertActions from '_actions/alert.actions';
import blockActions from '_actions/block.actions';

function success(order) {
  return {
    order,
    type: orderConstants.READ_SUCCESS,
  };
}

function action(id) {
  return (dispatch) => {
    dispatch(blockActions.block());
    get(`/api_auth/orders/aamos/order_read/${id}.json`).then(
      (order) => {
        if (isEmpty(order)) {
          dispatch(alertActions.error('Error reading order'));
          window.setTimeout(() => {
            window.location = '/dashboard';
          }, 2000);
          return;
        }
        dispatch(success(order));
        dispatch(blockActions.clear());
      },
      (error) => {
        dispatch(blockActions.clear());
        dispatch(alertActions.error(error.toString()));
      },
    );
  };
}

export default action;
