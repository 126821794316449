import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert, Button, Card, CardHeader, CardBody, CustomInput, FormGroup, Form, Input, Label,
} from 'reactstrap';
import { map, size, isEmpty, sumBy } from 'lodash';
import ModalTrigger from 'components/modalTrigger';
import { getOptions, add, addCustom } from '_actions/orderDelivery.actions';
import Currency from 'utilities/currency';
import config from 'utilities/config';

function AddDelivery() {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [freeDelivery, setFreeDelivery] = useState(false);
  const { options } = useSelector((state) => state.orderDelivery);
  const {
    Order: {
      id: orderId,
      delivery_postcode,
    },
    OrderProduct,
  } = useSelector((state) => state.order.read);
  const dispatch = useDispatch();

  useEffect(() => dispatch(getOptions(orderId)), [orderId, OrderProduct, dispatch]);

  const onChange = ({ target: { name, value } }) => setSelectedOptions((state) => ({ ...state, [name]: value }));

  return (
    <ModalTrigger title="Add Delivery" trigger={<Button color="primary" block disabled={isEmpty(OrderProduct)}>Add Delivery to {delivery_postcode}</Button>}>
      {sumBy(OrderProduct, 'line_price') >= config.freeDeliveryTheshold && (
        <Alert color="success">
            <CustomInput
              type="switch"
              id="free_delivery"
              label={freeDelivery ? 'Delivery cost has been overridden for this order' : 'Click here to apply free delivery to this order'}
              checked={freeDelivery}
              onChange={({ target: { checked } }) => setFreeDelivery(checked)}
            />
        </Alert>
      )}
      <Form key={1}>
        {map(options, ({ DeliverySchemeOption }, index) => (
          <Card className="mb-3" key={index}>
            <CardHeader className="p-2">Delivery {index + 1}</CardHeader>
            <CardBody className="p-2">
              {map(DeliverySchemeOption, ({ id, title, price }) => (
                <FormGroup key={id} check>
                  <Label check>
                    <Input type="radio" name={index} onChange={onChange} value={id} /> {title} - <Currency value={freeDelivery ? 0 : price} />
                  </Label>
                </FormGroup>
              ))}
            </CardBody>
          </Card>
        ))}
      </Form>
      <Button key={2} color="success" onClick={() => dispatch(add(selectedOptions, orderId, freeDelivery))} disabled={size(selectedOptions) !== size(options)} block>Save</Button>
      <hr />
      <Button key={3} color="info" onClick={() => dispatch(addCustom(orderId))} block>Add Custom Delivery</Button>
    </ModalTrigger>
  );
}

export default AddDelivery;
