import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card } from 'reactstrap';
import { isEmpty } from 'lodash';
import { submitRelatedRecord } from '_actions/order.actions';
import ModalTrigger from 'components/modalTrigger';
import FormInput from 'components/formInput';

function SetComments() {
  const {
    id, comments, internal_comments, order_number,
  } = useSelector((state) => state.order.read.Order);
  const [payload, setPayload] = useState({ comments, internal_comments, order_number });
  const dispatch = useDispatch();

  const onChange = ({ target: { id: fieldName, value } }) => setPayload((state) => ({
    ...state,
    [fieldName]: value,
  }));

  const hasComents = !isEmpty(comments) || !isEmpty(internal_comments) || !isEmpty(order_number);
  const title = (hasComents ? 'Edit' : 'Add') + ' Comments / Order Number';


  return (
    <div>
      <ModalTrigger title={title} trigger={<Button color="primary" block>{title}</Button>}>
        <FormInput
          label="Order Number"
          inputProps={{
            id: 'order_number',
            type: 'text',
            value: payload.order_number,
            onChange,
          }}
        />
        <FormInput
          label="Public Comment"
          inputProps={{
            id: 'comments',
            type: 'textarea',
            value: payload.comments,
            onChange,
            rows: 5,
          }}
        />
        <FormInput
          label="Internal Comment"
          inputProps={{
            id: 'internal_comments',
            type: 'textarea',
            value: payload.internal_comments,
            onChange,
            rows: 5,
          }}
        />
        <Button color="success" onClick={() => dispatch(submitRelatedRecord({ id, ...payload }, 'comments'))} block>Save</Button>
      </ModalTrigger>
      {hasComents && (
        <Card body className="pb-0">
          {order_number && <div><strong>Order Number</strong><p className="small">{order_number}</p></div>}
          {comments && <div><strong>Public Comment</strong><p className="small">{comments}</p></div>}
          {internal_comments && <div><strong>Internal Comment</strong><p className="small">{internal_comments}</p></div>}
        </Card>
      )}
    </div>
  );
}

export default SetComments;
