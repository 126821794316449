import { get } from '_services/request.service';
import orderConstants from '_constants/order.constants';
import alertActions from '_actions/alert.actions';
import blockActions from '_actions/block.actions';

function success(latestOrders) {
  return {
    latestOrders,
    type: orderConstants.LATEST_SUCCESS,
  };
}

function action(organisationId) {
  return (dispatch) => {
    dispatch(blockActions.block());
    get(`/api_auth/orders/aamos/latest_orders/${organisationId}.json`).then(
      (latestOrders) => {
        dispatch(blockActions.clear());
        dispatch(success(latestOrders));
      },
      (error) => {
        dispatch(blockActions.clear());
        dispatch(alertActions.error(error.toString()));
      },
    );
  };
}

export default action;
