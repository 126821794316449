const config = {
  dateFormat: 'DD/MM/YYYY',
  datetimeFormat: 'DD/MM/YYYY HH:mm',
  name: 'Agrovista Amenity Mobile Ordering System',
  companyRegistrationNumber: '3525529',
  localStorageKey: 'user',
  // todo get from env
  apiUrl: process.env.NODE_ENV !== 'production' ? 'https://pc.agrovista.dev' : 'https://maxcore.agrovista.co.uk',
  mediaUrl: 'https://maxcore-media.agrovista.co.uk',
  currency: '£',
  vatRate: '20.00',
  creditAccountTypeId: 2,
  freeDeliveryTheshold: 400,
};

export default config;
