import axios from 'axios';
import qs from 'qs';
import config from 'utilities/config';
import request from 'utilities/request';

function updateLocalStorage(user) {
  if (user.jwt_token) {
    localStorage.setItem(config.localStorageKey, JSON.stringify(user));
  }
}

function login(username, password) {
  const payload = qs.stringify({
    Member: {
      username,
      password,
    },
  });

  return axios.post('/api/login.json', payload)
    .then((response) => {
      const { data } = response;
      if (data.error) {
        return Promise.reject(data.error);
      }

      return data.user;
    })
    .then((user) => {
      updateLocalStorage(user);
      return user;
    });
}

function update(form) {
  const payload = qs.stringify(form);
  return axios.post('/api_auth/members/members/update.json', payload)
    .then((response) => {
      const { data } = response;
      if (data.error) {
        return Promise.reject(data.error.message);
      }
      return data.user;
    })
    .then((user) => {
      updateLocalStorage(user);
      return user;
    });
}

function logout() {
  localStorage.removeItem(config.localStorageKey);
  request.get('/api/logout.json');
}

export default {
  login,
  logout,
  update,
};
