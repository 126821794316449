import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { map, isEmpty, upperFirst } from 'lodash';
import { submitRelatedRecord, deleteRelatedRecord } from '_actions/order.actions';
import ModalTrigger from './modalTrigger';
import FormInput from './formInput';

function EditForm({
  record = {}, fields, type, trigger,
}) {
  const [payload, setPayload] = useState(record);
  const dispatch = useDispatch();
  useEffect(() => {
    setPayload(record);
  }, [record]);
  return (
    <ModalTrigger title={(payload.id ? 'Edit' : 'Add') + ' ' + upperFirst(type)} trigger={trigger}>
      {!isEmpty(payload) && map(fields, ({ label, inputProps }, index) => (
        <FormInput
          key={index}
          label={label}
          inputProps={{
            ...inputProps,
            value: payload[inputProps.name] || '',
            onChange: ({ target: { name, value } }) => setPayload({ ...payload, [name]: value }),
          }}
        />
      ))}
      <Button color="success" onClick={() => dispatch(submitRelatedRecord(payload, type))} block>Save</Button>
      {payload.id && (
        <div>
          <hr />
          <Button color="danger" onClick={() => dispatch(deleteRelatedRecord(payload, type))} block>Delete</Button>
        </div>
      )}
    </ModalTrigger>
  );
}

export default EditForm;
