import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useSelector } from 'react-redux';

function ModalTrigger({ children, trigger, title }) {
  const [modal, setModal] = useState(false);
  const { closeAll } = useSelector((state) => state.modal);
  const toggle = () => setModal(!modal);
  useEffect(() => {
    setModal(false);
  }, [closeAll]);
  return (
    <>
      {React.cloneElement(trigger, {
        onClick: () => {
          if (trigger.props.onClick) {
            trigger.props.onClick(!modal);
          }
          toggle();
        },
      })}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
      </Modal>
    </>
  );
}

export default ModalTrigger;
