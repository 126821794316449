import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { useSelector } from 'react-redux';
import config from 'utilities/config';
import Loader from 'utilities/loader';

function SessionExpiry() {
  const [duration, setDuration] = useState(0);
  setInterval(() => {
    const User = JSON.parse(localStorage.getItem(config.localStorageKey));
    if (User) {
      const decoded = jwt_decode(User.jwt_token);
      const newDuration = decoded.exp - moment().unix();
      setDuration(newDuration);
    }
  }, 1000);
  return duration ? <span>{moment.duration(duration, 'seconds').format()}.</span> : <Loader text={false} />;
}

function Footer() {
  const user = useSelector((state) => state.authentication.user);
  let content = null;
  if (user && user.title) {
    content = (
      <div className="text-center small">
        <p className="text-muted m-0">Logged in: <strong>{user.title}</strong>.</p>
        <p className="text-muted m-0 p-0">Your session will expire in <SessionExpiry /></p>
      </div>
    );
  }
  return (
    <footer className="footer mt-auto py-3">
      {content}
      <p className="text-center text-muted small">&copy; {(new Date()).getFullYear()} Agrovista UK Limited.</p>
    </footer>
  );
}
export default Footer;
