import { includes, lowerCase, filter } from 'lodash';
import organisationConstants from '_constants/organisation.constants';

function success(filtered, organisations) {
  return {
    filtered,
    organisations,
    type: organisationConstants.LIST_FILTER_SUCCESS,
  };
}

function action(searchTerm, organisations) {
  return (dispatch) => {
    const filtered = filter(organisations, ({ title }) => includes(
      lowerCase(title),
      lowerCase(searchTerm),
    ));
    dispatch(success(filtered, organisations));
  };
}

export default action;
